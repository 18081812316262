import React from "react";

const PortfolioPage = () => {
  return (
    <div>
      <h1>The portfolio page</h1>
    </div>
  );
};

export default PortfolioPage;
